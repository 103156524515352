import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => {
  // Gatsby renders the index page before checking the location to see if
  // routing is necessary. This means the index page flashes before the
  // requested page when you visit a route directly. This hack renders a
  // blank index page if the browser location is not pointing to the index.
  const isIndexPage = typeof window !== "undefined" && window.location.pathname === '/';
  return isIndexPage && (
    <Layout>
      <SEO title="Home" />
      <h1>Home</h1>
      <div>
        <Link to="/partner/">Partner with PagerDuty</Link>
      </div>
      <div>
        <Link to="/sign-up/">Developer Account Signup Form</Link>
      </div>
    </Layout>
  );
};

export default IndexPage;
